.wrap {
    position: relative;
    width: 100%;
}

.hints {
    animation: maxHeight300 .3s ease-out normal forwards;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 0 var(--box-shadow-radius) var(--box-shadows);
    font-size: 16rem;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-inline: 20px;
    position: absolute;
    top: calc(100% - var(--bpad, 20px));
    min-width: 100%;
    z-index: 20;
}

.hints p {
    cursor: pointer;
    margin-block: 22px;
}

@keyframes maxHeight300 {
    from {
        max-height: 0;
        opacity: .2;
    }

    to {
        max-height: 300px;
        opacity: 1;
    }
}